import React from 'react';
import { useTranslation } from 'react-i18next';

// const masonryItems = [
//     { id: 1, title: 'Thunder Ride from Sarana Dealer to Melasti Beach', span: 'col-span-1 row-span-1', image: '/placeholder.png' },
//     { id: 2, title: 'Dealers Booth', span: 'col-span-1 row-span-1', image: '/placeholder.png' },
//     { id: 3, title: 'Motorcycle Display Area', span: 'col-span-1 row-span-2', image: '/placeholder.png' },
//     { id: 4, title: 'Penalty Game - Inflatable', span: 'col-span-1 row-span-2 md:row-span-1', image: '/placeholder.png' },
//     { id: 5, title: 'Arcade Basketball', span: 'col-span-2 row-span-1', image: '/placeholder.png' },
//     { id: 6, title: 'Dunk Tank', span: 'col-span-1 row-span-2', image: '/placeholder.png' },
//     { id: 7, title: 'Bull Riding', span: 'col-span-1 md:col-span-2 row-span-1 md:row-span-2', image: '/placeholder.png' },
//     { id: 8, title: 'Dining Area with F&B Booth', span: 'col-span-1 row-span-1', image: '/placeholder.png' },
//     { id: 9, title: 'Sponsor Booth', span: 'col-span-2 row-span-1', image: '/placeholder.png' },
//     { id: 10, title: 'Custom Motorcycle Display HD Custom Kings Asia (Indonesia)', span: 'col-span-1 row-span-2', image: '/placeholder.png' },
//     { id: 11, title: 'Official Festival T-shirt', span: 'col-span-1 row-span-2 md:row-span-1', image: '/placeholder.png' },
//     { id: 12, title: 'HOG Lounge', span: 'col-span-1 md:col-span-2 row-span-2', image: '/placeholder.png' },
//     { id: 13, title: 'HD Apparels & Accessories', span: 'col-span-1 row-span-2 md:row-span-1', image: '/placeholder.png' },
// ];

const MasonryGrid = () => {
    const { t } = useTranslation();

    const masonryItems = [
        { id: 1, title: t("attraction.entertainment"), span: 'col-span-2 row-span-1 md:row-span-2 ', image: '/img/guest/rif.png' },
        // { id: 2, title: 'Merchandise', span: 'col-span-1 row-span-2 md:row-span-1', image: '/img/activities/merchandise.png' },
        { id: 2, title: 'Dunk Tank', span: 'col-span-1 row-span-2 md:row-span-1', image: '/img/activities/dunk.jpg' },
        { id: 3, title: 'Bull Riding', span: 'col-span-1 row-span-2 md:row-span-1', image: '/img/activities/bull.png' },
        { id: 4, title: t("attraction.exhibition"), span: 'col-span-2 row-span-1', image: '/img/activities/exhibition.jpeg' },
    ];

    return (
        <div className="px-4 md:px-24 py-24 mt-12">
            <h1 className="text-4xl text-white text-center mb-8 font-bold uppercase">{t('navbar.activities')}</h1>

            {/* Grid Container */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {masonryItems.map((item) => (
                    <div
                        key={item.id}
                        style={{
                            backgroundImage: `url(${item.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        className={`relative font-bold rounded-lg shadow-lg overflow-hidden flex items-end p-4 min-h-40 ${item.span}`}
                    >
                        {item.title} {item.title2 && (
                            <>
                                <br />
                                {item.title2}
                            </>
                        )}
                    </div>
                ))}
            </div>

        </div>
    );
};


export default MasonryGrid;
