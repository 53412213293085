import React from 'react';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
    const { t } = useTranslation();

    // const images = Array.from({ length: 10 }, (_, index) => `/img/gallery/${index + 1}.png`);

    const imgClass = "object-cover w-full h-full rounded-md shadow-lg";
    const images = [
        "/img/gallery/DSC05419.jpg",
        "/img/gallery/DSC05434.jpg",
        "/img/gallery/DSC05441.jpg",
        "/img/gallery/DSC05453.jpg",
        "/img/gallery/DSC05467.jpg",
        "/img/gallery/DSC05468.jpg",
        "/img/gallery/DSC05470.jpg",
        "/img/gallery/DSC05490.jpg",
        "/img/gallery/DSC05496.jpg",
        "/img/gallery/DSC05503.jpg",
        "/img/gallery/DSC05505.jpg",
        "/img/gallery/DSC05510.jpg",
        "/img/gallery/DSC05528.jpg",
        "/img/gallery/DSC05532.jpg",
        "/img/gallery/DSC05565.jpg",
        "/img/gallery/DSC05575.jpg",
        "/img/gallery/DSC05592.jpg",
        "/img/gallery/DSC05682.jpg",
        "/img/gallery/DSC05684.jpg",
        "/img/gallery/DSC05688.jpg",
        "/img/gallery/DSC05714.jpg",
        "/img/gallery/DSC05715.jpg",
        "/img/gallery/DSC05720.jpg",
        "/img/gallery/DSC05739.jpg",
        "/img/gallery/DSC05781.jpg",
        "/img/gallery/DSC05782.jpg",
        "/img/gallery/DSC05788.jpg",
        "/img/gallery/DSC05792.jpg",
        "/img/gallery/IMG_0244.jpg",
        "/img/gallery/IMG_0254.jpg",
        "/img/gallery/IMG_0263.jpg",
        "/img/gallery/IMG_0271.jpg",
        "/img/gallery/IMG_0287.jpg",
        "/img/gallery/IMG_0310.jpg",
        "/img/gallery/IMG_0314.jpg",
        "/img/gallery/IMG_0315.jpg",
        "/img/gallery/IMG_0323.jpg",
        "/img/gallery/IMG_0331.jpg",
        "/img/gallery/IMG_0411.jpg",
        "/img/gallery/IMG_0412.jpg",
        "/img/gallery/IMG_0423.jpg",
        "/img/gallery/IMG_0424.jpg",
        "/img/gallery/IMG_0444_1.jpg",
        "/img/gallery/IMG_0472.jpg",
        "/img/gallery/IMG_0486.jpg",
        "/img/gallery/IMG_0490.jpg",
        "/img/gallery/IMG_0491.jpg",
        "/img/gallery/IMG_0499.jpg",
        "/img/gallery/IMG_0510.jpg",
        "/img/gallery/IMG_0525.jpg",
        "/img/gallery/IMG_0647.jpg",
        "/img/gallery/IMG_8951.jpg",
        "/img/gallery/IMG_8965.jpg",
        "/img/gallery/IMG_8971.jpg",
        "/img/gallery/IMG_8996.jpg",
        "/img/gallery/IMG_9010.jpg",
        "/img/gallery/IMG_9042.jpg",
        "/img/gallery/IMG_9050.jpg",
        "/img/gallery/IMG_9052.jpg",
        "/img/gallery/IMG_9062.jpg",
        "/img/gallery/IMG_9064.jpg",
        "/img/gallery/IMG_9130.jpg",
        "/img/gallery/IMG_9147.jpg",
        "/img/gallery/IMG_9216.jpg",
        "/img/gallery/IMG_9224.jpg",
        "/img/gallery/IMG_9235.jpg",
        "/img/gallery/IMG_9247.jpg",
        "/img/gallery/IMG_9248.jpg",
        "/img/gallery/IMG_9250.jpg",
        "/img/gallery/IMG_9257.jpg",
        "/img/gallery/IMG_9273.jpg",
        "/img/gallery/IMG_9279.jpg",
        "/img/gallery/IMG_9289.jpg",
        "/img/gallery/IMG_9357_1.jpg",
        "/img/gallery/IMG_9379_1.jpg",
        "/img/gallery/IMG_9409.jpg",
        "/img/gallery/IMG_9415.jpg",
        "/img/gallery/IMG_9419.jpg",
        "/img/gallery/IMG_9489.jpg",
        "/img/gallery/IMG_9509.jpg",
    ];

    const grids = [
        [0, 1, 2], // Indices for the first grid structure
        [3, 4, 5], // Indices for the second grid structure
        [6, 7, 8], // Indices for the third grid structure
    ];

    return (
        <div className="px-0 md:px-24 py-24 mt-12">
            <h1 className="text-center text-4xl font-bold mb-4">{t("ihf_gallery")}</h1>

            {/* Gallery Sections */}
            <div className="space-y-4 flex flex-col items-center px-4">
                <div>
                    <div className="flex w-full items-center justify-center mb-16">
                        <div className="w-full max-w-4xl aspect-video">
                            <iframe
                                className="w-full h-full"
                                src="https://www.youtube.com/embed/30JStiuNLTU?si=rIZkgrMXGCnL9gQF"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>


                    {Array.from({ length: Math.ceil(images.length / 9) }).map((_, gridIndex) => (
                        <div key={gridIndex}>
                            {grids.map((grid, i) => {
                                const start = gridIndex * 9 + grid[0];
                                const gridImages = grid.map((offset) => images[start + offset]).filter(Boolean);

                                return (
                                    <div className="grid grid-cols-3 gap-4 mb-4" key={`${gridIndex}-${i}`}>
                                        {gridImages.map((src, index) => (
                                            <div
                                                className={`relative ${(i === 0 && index === 0) || (i === 2 && index === 1)
                                                    ? "col-span-2 row-span-2"
                                                    : src === "/img/gallery/29.jpg" ? "row-span-2" : "row-span-1"
                                                    }`}
                                                key={src}
                                            >
                                                <img src={src} className={imgClass} />
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
