import React from "react";
import { FaInstagram, FaTwitter, FaFacebook, FaYoutube } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const hide = false;

const ArtistsPage = () => {
    const { t } = useTranslation();

    const artistsData = [

        {
            name: "/rif",
            description: t("artists.rif"),
            image: "/img/guest/rif.png",
            socials: {
                instagram: "https://www.instagram.com/rifband.official/",
                twitter: "https://x.com/rifbandofficial",
                youtube: "https://www.youtube.com/watch?v=8_xJWGXmL0Y",
            },
        },

        {
            name: "Olive Purple",
            description: t("artists.olive"),
            image: "/img/guest/olive_purple.png",
            socials: {
                instagram: "https://www.instagram.com/olivepurple_official/",
                twitter: "https://x.com/olivepurpledj?lang=id",
                youtube: "https://www.youtube.com/watch?v=xjvO-OxDjQQ",
            },
        },

        {
            name: "The Hydrant",
            description: t("artists.hydrant"),
            image: "/img/guest/hydrant.jpg",
            socials: {
                instagram: "https://www.instagram.com/thehydrantofficial?igsh=MWV3Zm03ZWQza28zYg==",
                twitter: "https://twitter.com/thehydrantball?lang=bn",
                youtube: "https://www.youtube.com/channel/UCvy3JYI7xq3HrnEHP-nqMgw",
            },
        },

        {
            name: "Shinta Gisul",
            description: t("artists.shinta"),
            image: "/img/guest/shinta_gisul.jpg",
            socials: {
                instagram: "https://www.instagram.com/shinta.gisul/",
                twitter: "https://twitter.com/ShintaGisul",
                youtube: "https://www.youtube.com/c/shintagisulofficial",
            },
        },

        {
            name: "ROBOKOP",
            description: t("artists.robokop"),
            image: "/img/guest/robokop.jpeg",
            socials: {
                instagram: "https://www.instagram.com/robokop_koplo/",
                twitter: "https://twitter.com/Robokop_lo/status/1487962377559887873",
                youtube: "https://www.youtube.com/@RobokopKoploan",
            },
        },
    ];


    if (hide) {
        return (
            <div className="mx-auto px-12 md:px-24 py-24 mt-24">
                <h1 className="text-[4rem] font-bold text-center mb-6">
                    {t("coming-soon")}
                </h1>
            </div>
        )
    }

    return (

        <div className="mx-auto px-12 md:px-24 py-24 mt-12">
            <h1 className="text-4xl font-bold text-center mb-6 uppercase">
                {t("artist")}
            </h1>
            <div>
                {artistsData.map((artist, index) => (
                    <div
                        key={index}
                        className={`flex flex-col md:flex-row ${index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
                            } overflow-hidden mb-8`}
                    >
                        {/* Image Section */}
                        <img
                            src={artist.image}
                            alt={artist.name}
                            className={`w-full md:w-1/2 h-full md:h-auto object-cover ${artist.name !== "/rif" && "aspect-square"}`}
                        />
                        {/* Description and Socials Section */}
                        <div className={`p-4 md:p-12 md:w-1/2 flex flex-col justify-center ${index % 2 === 0 ? "items-end" : "items-start"
                            }`}>
                            <h2 className="text-3xl font-semibold">{artist.name}</h2>
                            <p className={`mt-2 text-justify ${index % 2 !== 0 ? "md:text-start" : "md:text-end"}`}>{artist.description}</p>
                            <div className="flex items-center gap-4 mt-4">
                                {artist.socials.instagram && (
                                    <a
                                        href={artist.socials.instagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
                                    >
                                        <FaInstagram />
                                    </a>
                                )}
                                {artist.socials.twitter && (
                                    <a
                                        href={artist.socials.twitter}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
                                    >
                                        <FaTwitter />
                                    </a>
                                )}
                                {artist.socials.youtube && (
                                    <a
                                        href={artist.socials.youtube}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
                                    >
                                        <FaYoutube />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ArtistsPage;
