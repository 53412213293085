import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../lib/LanguageContext';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
    const { t } = useTranslation();
    const { language, switchLanguage } = useLanguage();
    const [activeSection, setActiveSection] = useState('home');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const mobileMenuRef = useRef(null);
    const location = useLocation();

    const toggleMobileMenu = () => setMobileMenuOpen(prev => !prev);

    const handleClickOutside = (event) => {
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            setMobileMenuOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener to handle clicks outside the menu
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentSection = '';
            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if (window.scrollY >= sectionTop - sectionHeight / 3 && window.scrollY < sectionTop + sectionHeight - sectionHeight / 3) {
                    currentSection = section.getAttribute('id');
                }
            });
            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initialize active section on load

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Scroll to top on route change
    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, [location.pathname]);

    // Function to determine if the link is active based on current URL
    const isActive = (path) => {
        return location.pathname === path
            ? "text-black" // Apply active styles
            : "text-white"; // Apply normal styles
    };

    return (
        <>
            <nav className="fixed top-0 right-0 opacity-100 md:rounded-none w-full bg-orange py-4 md:py-2 px-4 md:px-8 flex flex-col md:items-center z-50 text-sm md:text-lg">
                <div className="w-full flex items-center justify-between gap-2 mt-2 md:mt-0 md:space-x-4">
                    <div className='hidden md:flex flex-row gap-4 items-center'>
                        <img src="/logo.png" alt="Harley-Davidson Logo" className="md:h-9" />
                        <p className='headline text-white text-2xl'>INDONESIA HARLEY® FEST</p>
                    </div>

                    {/* Language Buttons */}
                    <div className='hidden md:flex flex-row gap-2'>
                        <button
                            onClick={() => switchLanguage('id')}
                            className={`${language === 'id' ? 'text-white' : 'text-orange-300'} md:py-2 rounde flex gap-2 items-center`}
                        >
                            <img
                                src='/indonesia.png'
                                alt='Indonesian Flag'
                                className="w-4 h-4 object-cover rounded-full"
                            />
                            ID
                        </button>

                        <button
                            onClick={() => switchLanguage('en')}
                            className={`${language === 'en' ? 'text-white' : 'text-orange-300'} md:py-2 rounde flex gap-2 items-center`}
                        >
                            <img
                                src='/united-kingdom.png'
                                alt='United Kingdom Flag'
                                className="w-4 h-4 object-cover rounded-full"
                            />
                            EN
                        </button>
                    </div>
                </div>

                {/* Links */}
                <div className="w-full hidden md:flex justify-between items-center md:mb-0 mt-2 md:mt-0 md:space-x-4 text-[1.35rem] headline text-center uppercase">
                    {[
                        { path: '/', label: 'IHF2025' },
                        // { path: '/registration', label: t('navbar.registration') },
                        { path: '/program', label: t('navbar.program') },
                        { path: '/artists', label: t('navbar.artists') },
                        { path: '/activities', label: t('navbar.activities') },
                        { path: '/venue', label: t('navbar.venue') },
                        { path: '/hotel', label: t('navbar.hotel') },
                        { path: '/sponsors', label: t('navbar.sponsors') },
                        { path: '/gallery', label: t('navbar.gallery') },
                        { path: '/contact', label: t('navbar.contact') },
                    ].map(link => (
                        <Link
                            key={link.path}
                            to={link.path}
                            className={`hover:text-orange-100 ${isActive(link.path)}`}
                        >
                            {link.label}
                        </Link>
                    ))}
                </div>

                {/* Mobile Menu */}
                <div className="md:hidden flex justify-between">
                    <div className='md:hidden flex flex-row gap-2 items-center'>
                        <img src="/logo.png" alt="Harley-Davidson Logo" className="h-9" />
                        <p className='headline text-white text-2xl'>INDONESIA HARLEY® FEST</p>
                    </div>

                    <button
                        onClick={toggleMobileMenu}
                        className="text-white focus:outline-none"
                    >
                        {mobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>

                {mobileMenuOpen && (
                    <div
                        ref={mobileMenuRef}
                        className="absolute top-full left-0 w-full bg-orange text-white py-4 z-40 flex flex-col gap-4 text-right px-4 text-lg md:hidden"
                    >

                        <div className='headline flex flex-col gap-3 text-2xl uppercase'>
                            {[
                                { path: '/', label: 'IHF2025' },
                                // { path: '/registration', label: t('navbar.registration') },
                                { path: '/program', label: t('navbar.program') },
                                { path: '/artists', label: t('navbar.artists') },
                                { path: '/activities', label: t('navbar.activities') },
                                { path: '/venue', label: t('navbar.venue') },
                                { path: '/hotel', label: t('navbar.hotel') },
                                { path: '/sponsors', label: t('navbar.sponsors') },
                                { path: '/gallery', label: t('navbar.gallery') },
                                { path: '/contact', label: t('navbar.contact') },
                            ].map(link => (
                                <Link
                                    key={link.path}
                                    to={link.path}
                                    onClick={toggleMobileMenu}
                                    className={`hover:text-orange-100 ${isActive(link.path)}`}
                                >
                                    {link.label}
                                </Link>
                            ))}
                        </div>

                        {/* Language Buttons */}
                        <div className='flex md:hidden flex-row gap-2 justify-end text-xl'>
                            <button
                                onClick={() => switchLanguage('id')}
                                className={`${language === 'id' ? 'text-white' : 'text-orange-300'} md:py-2 flex gap-2 items-center`}
                            >
                                <img
                                    src='/indonesia.png'
                                    alt='Indonesian Flag'
                                    className="w-4 h-4 object-cover rounded-full"
                                />
                                ID
                            </button>

                            <button
                                onClick={() => switchLanguage('en')}
                                className={`${language === 'en' ? 'text-white' : 'text-orange-300'} md:py-2 rounde flex gap-2 items-center`}
                            >
                                <img
                                    src='/united-kingdom.png'
                                    alt='United Kingdom Flag'
                                    className="w-4 h-4 object-cover rounded-full"
                                />
                                EN
                            </button>
                        </div>
                    </div>
                )}
            </nav>
        </>
    );
};

export default Navbar;
