import React from 'react';
import { useTranslation } from 'react-i18next';

const Programs = () => {
    const { t } = useTranslation();

    const schedule = [
        { start: "13:00", end: "13:03", duration: "00:03", program: "Open Gate" },
        { start: "13:03", end: "13:30", duration: "00:27", program: "MC Opening" },
        { start: "13:30", end: "14:00", duration: "00:30", program: "MC Games" },
        { start: "14:00", end: "14:15", duration: "00:15", program: "Sponsor Activity 1" },
        { start: "14:15", end: "14:30", duration: "00:15", program: "MC Games" },
        { start: "14:30", end: "15:00", duration: "00:30", program: "Idle Time - TVC Looping" },
        { start: "15:00", end: "16:00", duration: "01:00", program: "Lime Band" },
        { start: "16:15", end: "16:30", duration: "00:15", program: "Preparation for Opening Ceremony" },
        {
            start: "16:30",
            end: "17:15",
            duration: "00:45",
            program: "Opening Ceremony:\n- Opening Dance\n- Speech Opening\n- Procession Chapter Introduction"
        },
        { start: "17:15", end: "18:00", duration: "00:45", program: "Robokop" },
        { start: "18:00", end: "19:00", duration: "01:00", program: "Break (Preparation for The Hydrant)" },
        { start: "19:00", end: "19:45", duration: "00:45", program: "The Hydrant" },
        { start: "19:45", end: "19:50", duration: "00:05", program: "Preparation Awarding" },
        { start: "19:50", end: "20:30", duration: "00:40", program: "Awarding" },
        { start: "20:30", end: "20:40", duration: "00:10", program: "Talk Show: Custom Kings Asia" },
        { start: "20:40", end: "21:15", duration: "00:35", program: "Shinta Gisul" },
        { start: "21:15", end: "21:30", duration: "00:15", program: "Preparation /rif" },
        { start: "21:30", end: "21:35", duration: "00:05", program: "Lighting Show" },
        { start: "21:35", end: "22:20", duration: "00:45", program: "/rif" },
        { start: "22:20", end: "23:00", duration: "00:40", program: "DJ Olive Purple" },
    ];

    return (
        <div className="px-4 md:px-24 md:max-w-4xl md:mx-auto py-24 mt-12 flex flex-col items-center text-white relative">
            <h1 className="text-4xl font-bold text-center mb-8">PROGRAM</h1>

            <div className="overflow-x-auto w-full">
                <table className="table-auto w-full text-center border-collapse">
                    <thead>
                        <tr className="bg-orange-600 text-white">
                            <th className="px-4 py-2 border border-white">START</th>
                            <th className="px-4 py-2 border border-white">END</th>
                            <th className="px-4 py-2 border border-white">DURATION</th>
                            <th className="px-4 py-2 border border-white min-w-48">PROGRAM</th>
                        </tr>
                    </thead>
                    <tbody>
                        {schedule.map((item, index) => (
                            <tr key={index} className="bg-transparent">
                                <td className="px-4 py-2 border border-orange-600">{item.start}</td>
                                <td className="px-4 py-2 border border-orange-600">{item.end}</td>
                                <td className="px-4 py-2 border border-orange-600">{item.duration}</td>
                                <td className="px-4 py-2 border border-orange-600 whitespace-pre-line">{item.program}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Programs;
