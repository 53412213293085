import React from 'react';
import { useTranslation } from 'react-i18next';

const Sponsors = () => {
    const { t } = useTranslation();

    const sponsors1 = [
        { name: "Harley-Davidson", logo: "/img/brands/harley_davidson_long.png", style: "w-auto h-[20px] object-contain" },
        { name: "JLM Auto", logo: "/img/brands/jlmauto_white.png", style: "w-auto h-[62px] object-contain" },
    ];

    const sponsors = [
        {
            name: "My Pertamina",
            logo: "/img/brands/mypertamina.png",
            style: "w-auto h-10 object-contain"
        },
        {
            name: "Midaz Golf",
            logo: "/img/brands/midaz.png",
            style: "w-auto h-10 object-contain"
        },
        {
            name: "JNE",
            logo: "/img/brands/jne.png",
            style: "w-auto h-10 object-contain"
        },
        {
            name: "Breman Brewery",
            logo: "/img/brands/breman.png",
            style: "w-auto h-10 object-contain"
        },
        {
            name: "Outlet 23",
            logo: "/img/brands/outlet23.png",
            style: "w-auto h-14 object-contain"
        },
        {
            name: "Adora",
            logo: "/img/brands/adora.png",
            style: "w-auto h-10 object-contain"
        },
    ]

    return (
        <div className="px-12 md:px-24 py-24 mt-12">
            {/* Thank You Header */}
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold mb-4">{t("sponsors.title")}</h1>
                <div className='text-lg flex flex-col'>
                    <p>{t("sponsors.subtitle")}</p>
                    <p>{t("sponsors.contact1")}</p>
                    <p>{t("sponsors.contact2")}</p>
                </div>
            </div>

            {/* <hr className='mb-8' /> */}

            {/* Sponsors Grid */}
            <div className="flex flex-col sm:flex-row gap-8 items-center justify-center">
                {sponsors1.map((sponsor, index) => (
                    <div key={index} className="flex flex-col items-center justify-center">
                        <img
                            src={sponsor.logo}
                            alt={sponsor.name}
                            className={sponsor.style}
                        />
                    </div>
                ))}
            </div>
            <div className="flex flex-col gap-8 items-center justify-center mt-16">
                <h1 className='text-3xl'>SPONSORED BY:</h1>
                <div className='flex flex-col md:flex-row gap-8'>
                    {sponsors.map((sponsor, index) => (
                        <div key={index} className="flex flex-col items-center justify-center">
                            <img
                                src={sponsor.logo}
                                alt={sponsor.name}
                                className={sponsor.style}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex flex-col gap-8 items-center justify-center mt-16">
                <h1 className='text-3xl'>MEDIA PARTNER:</h1>
                <div className='flex flex-col md:flex-row gap-8'>
                    <div className="flex flex-col items-center justify-center">
                        <img
                            src="/img/brands/mdtv.png"
                            alt="MDTV"
                            className="w-auto h-10 object-contain"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sponsors;
